<template>
  <div class="a85f44a28c92cdb37533e52c6364916468196b1c">
    <title>Site Under Contruction</title>
    <h1>I&rsquo;ll be back eventually!</h1>
    <div>
      <p>
        The state of the website right now is offline for a rework. If you try
        to go anywhere else it will redirect you here or to a 404.
      </p>
      <p>
        If you need something feel free to contact me on
        <a href="https://discord.gg/balls">discord</a>.
      </p>
      <br />
      <br />
      <p>&mdash; Website Owner</p>
    </div>
  </div>
</template>

<style src="@/assets/css/offline.css"></style>

<script>
export default {
  name: "Offline",
};
</script>
